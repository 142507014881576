import type {MetaFunction} from "@remix-run/react"
import {Link} from "@remix-run/react/dist/components"
import {getSeoMeta} from "@shopify/hydrogen"
import clsx from "clsx"

import {Button} from "~/components/elements/Button"
import {IconBulb, IconScale, IconShield} from "~/components/elements/Icon"
import {ContactModal} from "~/components/global/ContactModal"
import {
  Blurb,
  BlurbHighlight,
  BlurbHighlightsContainer,
} from "~/components/sections/Blurb"
import {Hero} from "~/components/sections/Hero"
import bgImage from "~/images/hero/hero-homepage.avif"
import bgImage2 from "~/images/hero/hero-lp-variant.avif"
import microsoft from "~/images/partners/microsoft-gs.svg"
import veam from "~/images/partners/veam-gs.svg"
import vmware from "~/images/partners/vmware-gs.svg"
import microsoft365 from "~/images/svgs/365.svg"
import database from "~/images/svgs/database.svg"
import exchange from "~/images/svgs/exchange.svg"
import office from "~/images/svgs/office.svg"
import os from "~/images/svgs/os.svg"
import project from "~/images/svgs/project.svg"
import sharepointHome from "~/images/svgs/sharepoint.svg"
import visio from "~/images/svgs/visio.svg"

export const handle = {
  seo: () => ({
    title: "Home",
  }),
}

export const meta: MetaFunction = ({matches}) => {
  return getSeoMeta(...matches.map((_) => (_.handle as any)?.seo?.(_)))
}

const HomePage = () => {
  return (
    <>
      <Hero
        title="Unlock your possibilities with cloud solutions"
        description="We provide the industry solutions your business needs to grow"
        centered={true}
        bgImage={bgImage}
        callToAction={
          <ContactModal
            activator={
              <Button size="lg" className="mt-2" variant="secondary-alternate">
                Speak to an expert
              </Button>
            }
          />
        }
      />
      <div className="container-lg mx-auto pt-3 md:pt-5 text-center">
        <h2 className="text-heading">Proven cloud solutions</h2>
        <p className="mt-2 mb-3">
          Cloud solutions for enhancing infrastructure and expanding operations.
        </p>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
          <HomeProductCard
            title="Microsoft 365"
            url="/microsoft/microsoft-365"
            imagePath={microsoft365}
            imageSize={104}
            bordered={true}
          />
          <HomeProductCard
            title="Exchange"
            url="/microsoft/exchange"
            imagePath={exchange}
            imageSize={104}
            bordered={true}
          />
          <HomeProductCard
            title="Sharepoint"
            url="/microsoft/sharepoint"
            imagePath={sharepointHome}
            imageSize={104}
            bordered={true}
          />
          <HomeProductCard
            title="Visio"
            url="/microsoft/visio"
            imagePath={visio}
            imageSize={104}
            bordered={true}
          />
        </div>
      </div>

      <div className="container-lg mx-auto pt-4 md:pt-7 text-center">
        <h2 className="text-heading">Flexibility for your business</h2>
        <p className="mt-2 mb-3">
          From operating systems and creative software to building the IT
          infrastructure you need to enhance your operational efficiency.
        </p>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
          <HomeProductCard
            title="Office"
            url="/microsoft/office"
            imagePath={office}
            imageSize={52}
            bordered={true}
          />
          <HomeProductCard
            title="Project"
            url="/microsoft/project"
            imagePath={project}
            imageSize={52}
            bordered={true}
          />
          <HomeProductCard
            title="SQL Server"
            url="/microsoft/sql-server"
            imagePath={database}
            imageSize={52}
            bordered={true}
          />
          <HomeProductCard
            title="Windows Server"
            url="/microsoft/windows-server"
            imagePath={os}
            imageSize={52}
            bordered={true}
          />
          <Link to="/products" className="col-span-2 md:col-span-4 underline">
            View all products
          </Link>
        </div>
      </div>
      <Blurb
        title="Performance at your reach"
        body="Whether you're looking to streamline processes, boost productivity, or drive growth, our solutions are designed to help you achieve your goals."
        centerBody={true}
        className="pt-4 pb-5 md:py-7"
      >
        <BlurbHighlightsContainer wideGap={true} inline>
          <BlurbHighlight icon={<IconBulb size="lg" />} title="Expertise" />
          <BlurbHighlight icon={<IconScale size="lg" />} title="Scalability" />
          <BlurbHighlight icon={<IconShield size="lg" />} title="Support" />
        </BlurbHighlightsContainer>
      </Blurb>
      <Hero
        title="Speak to an expert"
        description="Get in touch with one of our solutions expert today and start streamlining your business."
        centered={true}
        bgImage={bgImage2}
        callToAction={
          <ContactModal
            activator={
              <Button size="lg" className="mt-2" variant="secondary-alternate">
                Contact us
              </Button>
            }
          />
        }
      />
      <Blurb
        title="Our PartnerShips"
        centerBody={true}
        className="py-5 md:py-7"
      >
        <BlurbHighlightsContainer wideGap={true} inline>
          <img src={microsoft} alt="MicrosoftPartner" />
          <img src={vmware} alt="Vmware" />
          <img src={veam} alt="Veam" />
        </BlurbHighlightsContainer>
      </Blurb>
    </>
  )
}

type HomeProductCardProps = {
  title: string
  url: string
  imagePath?: string
  imageSize?: number
  bordered?: boolean
}

const HomeProductCard = ({
  title,
  url,
  imagePath,
  imageSize,
  bordered,
}: HomeProductCardProps) => {
  const classes = clsx(
    "flex flex-col items-center p-2",
    bordered && "border border-ash hover:border-mist rounded-lg duration-100",
  )

  return (
    <Link to={url} className={classes}>
      <img src={imagePath} width={imageSize} height={imageSize} alt={title} />
      <h4 className="mt-1 text-spacemist text-subheading-2 font-semibold">
        {title}
      </h4>
    </Link>
  )
}

export default HomePage
